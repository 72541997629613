.popup-qty{
    display: flex;
}

.popup-qty__container{
    height: 6em;
    width: 8em;
    font-size: 0.8em;
    padding: 0 0.5em;
}

.popup-qty__container_ship{
    color: #de5b01;
}

.popup-qty__container_ship .card-labeled{
    border-color: #de5b01;
    color: #de5b01;
}
.popup-qty__container_ship .card-labeled__label{
    background-color: #de5b01
}