.grid-inventory {
    display: flex;
    height: 16.25em;
}


.grid-inventory__container{
    padding: 1.4375em 1.125em 1.625em;
}


.grid-inventory__container_id {
    flex: 28 28 2.8em;
}

.grid-inventory__container_info {
    flex: 66 66 6.6em;
    border-left: #6f6f6f 1px solid;
}

.grid-inventory__container_props {
    flex: 87 87 8.7em;
    border-left: #6f6f6f 1px solid;
}

.test {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: steelblue;
    color: white;
}