.switch-require-signature{
    padding: 2em;
}

.switch-require-signature__checkbox.ui.checkbox.toggle{
    font-size: 1.9em;
}

.switch-require-signature__checkbox.ui.checkbox.toggle label{

}