.modal-report__content{
    min-height: 30vw;
}

.dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer{
    display: flex!important;
}

.ui.modal>.modal-report.content{
    position: static;
    min-height: 100%;
}

.modal-report__container_actions{
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.modal-report__last-report-date{
    font-size: 1.2em;
    margin: 0 auto 0 1em;
    align-self: center;
}