.card-image{

    border: #6f6f6f 1px solid;
    font: 500 1.3em sans-serif;
    align-items: center;
    justify-content: center;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
}

.card-image__image{
    max-height: -webkit-fill-available;
    max-width: -webkit-fill-available;
    max-height: -moz-available;
    max-width: -moz-available;
    max-height: 100%;
    max-width: 100%;
}

.card-image__reference{

}