.grid {
    display: grid;
    width: 100%;
    align-items: center;
    margin: 0;
}

.grid__container{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}