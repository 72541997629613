html,
body,
#root {
  min-height: 100%;
}

.ui.purple.button, .ui.purple.buttons .button{
  background-color: #6e69c7;
}

.ui.purple.button:focus, .ui.purple.buttons .button:focus{
  background-color: #6e69c7;
}

.ui.purple.button:hover, .ui.purple.buttons .button:hover{
  background-color: #4f4f9e;
}