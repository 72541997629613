.header_content{
    display: flex;
    width: 100%;
    height: 6.25em;
    padding: 0 1rem
}

.header__container{
    display: flex;
    flex-flow: row;
    flex-basis: 31em;
    align-items: center;
}

.header__counter{
    margin: auto 0.5em;
    flex-basis: 0;
    width: fit-content;
    color: #726ec2;
    font-size: 2.75em;
}

.header__text{
    font: 1.5em sans-serif;
}

.header__text_count{
    color: #726ec2;
    font-weight: bold;
    margin-right: 0.2em;
}

.header__dropdown .icon {
    position: absolute;
    right: 1em;
    top: 50%;
    color: #c6c6c6;
    font-size: 0.7em;
    transform: translateY(-50%);
}

.header__dropdown.ui.dropdown .menu {
    width: inherit;
    border: none;
}

.header__dropdown.ui.dropdown .menu>.item {
    width: inherit;
    background-color: #ececf2;
    font: 300 1em sans-serif;
}

.header__dropdown.ui.dropdown .menu>.item:hover{
    background-color: #c5c5cb;
}

.header__dropdown-label{
    min-width: fit-content;
    font: bolder 1.5em sans-serif;
    margin-right: 0.375em;
}