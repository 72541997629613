.panel-buy-info{
    grid-template-areas:
        "id"
        "id"
        "qty"
        "qty"
        "price"
        "profit";
}

.panel-buy-info__container_id { grid-area: id }
.panel-buy-info__container_qty { grid-area: qty }
.panel-buy-info__container_price { grid-area: price }
.panel-buy-info__container_profit { grid-area: profit }

.panel-buy-info__card-labeled__content {
    padding: 0;
    cursor: pointer;
}