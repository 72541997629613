body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #eaeaea;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
