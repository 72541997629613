.header-inventory{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 6.25em;
    font: 12px sans-serif;
    align-items: center;
    padding: 0 1rem;
}

.header-inventory__container{
    display: flex;
    flex-flow: row;
    flex: 170;
    margin: 0;
    align-items: center;
    padding: 0 0 0 1em;
}

.header-inventory__counter{
    padding: 0 0.5em 0 0.1em;
    flex: 0;
    width: fit-content;
    color: #726ec2;
    font-size: 2.75em;
    justify-self: flex-end;
}

.header-inventory__container_button{
    max-width:25%;
    height: 2.4rem;
    justify-self: flex-end;
    padding: 0 1.25em;
    /*border-left: black 1px solid;*/
}

.header-inventory__container_space{
    flex: 420;
}

.header-inventory__dropdown-label{
    min-width: fit-content;
    font: bolder 1.5em sans-serif;
    margin-right: 0.375em;
}

.header-inventory__dropdown {
    height: 2.23em;
    padding: 0.5em 0.875em;
    width: -moz-available;
    width: -webkit-fill-available;
    background-color: #ececf2;
    border: none;
    box-sizing: border-box;
    font: 300 1.5em sans-serif;
    border-radius: 6px;
    vertical-align: middle;
}


.header-inventory__dropdown .icon {
    position: absolute;
    right: 1em;
    top: 50%;
    color: #c6c6c6;
    font-size: 0.7em;
    transform: translateY(-50%);
}

.header-inventory__button, .header-inventory__button.ui.button{
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 40000px;
    background-color: #00c6de;
    color: white;
    font: 1.3em sans-serif;
    margin: 0;
}

.header-inventory__button.ui.icon.button>.icon{
    margin-right: 0.4em !important;
}

.header-inventory__button:hover, .header-inventory__button.ui.button:hover{
    background-color: #008899;
}

@media screen and (max-width: 1295px) {
    .header-inventory__container_button{
        padding: 0 1em;
        flex: 221;
        box-sizing: border-box;
    }
    .header-inventory {
        font: 10px sans-serif;
    }

    .header-inventory__dropdown .icon {
        right: 0.5em;
    }
}