.panel-distr-choice{
    grid-template-areas:
        "table"
        "table"
        "table"
        "table"
        "table"
        "button";
}

.panel-distr-choice__container_button{ grid-area: button; }
.panel-distr-choice__container_table{ grid-area: table; }
