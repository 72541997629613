.card-simple{
    background-color: #ececf2;
    border: #6f6f6f 1px solid;
    font: 500 1.3em sans-serif;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.card-simple_green{
    background-color: #d6efcc;
    color: #528329;
    font-weight: bold;
}

.card-simple__line{
    margin: 0;
}
