.header-order-search{
    display: flex;
    width: 100%;
    font: 12px sans-serif;
    height: 6.25em;
}

.header-order-search__container{
    display: flex;
    flex-flow: row;
    flex-basis: 31em;
    margin: 0 1.09375em;
    align-items: center;
}

.header-order-search__container_search{
    flex-basis: 65em;
}

.header-order-search__container_bulk{
    width: 4em;
    height: 4em;
    align-self: center;
    flex: none;
}

.header-order-search__counter{
    margin: auto 0.5em;
    flex-basis: 0;
    width: fit-content;
    color: #726ec2;
    font-size: 2.75em;
}

.header-order-search__dropdown-label{
    min-width: fit-content;
    font: bolder 1.5em sans-serif;
    margin-right: 0.375em;
}

.header-order-search__dropdown {
    height: 2.23em;
    padding: 0.5em 0.875em;
    width: -moz-available;
    width: -webkit-fill-available;
    background-color: #ececf2;
    border: none;
    box-sizing: border-box;
    font: 300 1.5em sans-serif;
    border-radius: 6px;
    vertical-align: middle;
}

.header-order-search__search{
    width: 100%;
    height: 3.3em;
}

.header-order-search__search.ui.input>input{
    padding: 0.5em 0.875em;
    font: 300 1.5em sans-serif;
    border: #6e68c6 1px solid;
    color: #6e68c6;
    background-color: #f4f5f9;
}

.header-order-search__search.ui.input>input::placeholder{
    color: #b7b6dd;
}

.header-order-search__search>.icon {
    color: #6e68c6;
}

.header-order-search__dropdown .icon {
    position: absolute;
    right: 1em;
    top: 50%;
    color: #c6c6c6;
    font-size: 0.7em;
    transform: translateY(-50%);
}

.header-order-search__dropdown.ui.dropdown .menu {
    width: inherit;
    border: none;
}

.header-order-search__dropdown.ui.dropdown .menu>.item {
    width: inherit;
    background-color: #ececf2;
    font: 300 1em sans-serif;
}

.header-order-search__dropdown.ui.dropdown .menu>.item:hover{
    background-color: #c5c5cb;
}


@media screen and (max-width: 1295px) {
    .header-order-search__container_date{
        flex-basis: 31em;
    }

    .header-order-search__container_search{
        flex-basis: 45em;
    }

    .header-order-search {
        font: 10px sans-serif;
        padding: 0 0.3em;
    }

    .header-order-search__dropdown .icon {
        right: 0.5em;
    }
}