.nav-link__dropdown, .nav-link__dropdown.ui.dropdown {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    box-sizing: border-box;
    vertical-align: middle;
}

.nav-link__dropdown.ui.dropdown .menu {
    width: inherit;
    border: none;
    left: 50%;
    transform: translateX(-50%);
    border-top: 1px white solid;
}

.nav-link__dropdown.ui.dropdown .menu>.item {
    display: flex;
    width: inherit;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: rgba(128, 132, 144, 1);
    font: 300 0.8em sans-serif;
    padding-left: 0!important;
    padding-right: 0!important;
}

.nav-link__dropdown.ui.dropdown .menu>.item.active {
    background-color: #9d9bda;
    color: white;
}

.nav-link__dropdown.ui.dropdown .menu>.item:hover {
    background-color: #c5c5cb;
}