.input-date__container .DateInput{
    width: -moz-available;
    width: -webkit-fill-available;
}

.input-date__container .DateInput_input {
    height: 2.23em;
    padding: 0.5em 0.875em;
    background-color: #ececf2;
    border: none;
    box-sizing: border-box;
    font: 300 1.5em sans-serif;
    border-radius: 6px;
    vertical-align: middle;
}

.input-date__container .DateInput_fang {
    /*top: 3.5em !important;*/
    top: 34px !important;

}

.input-date__container .SingleDatePicker_picker {
    /*top: 4.5em !important;*/
    top: 44px !important;
}