.content-system{
    display: flex;
    padding: 0;
    justify-content: flex-start;
    height: fit-content;
    font: 16px sans-serif;
    border: #c5c5cb 1px solid
}

.content-system__container{
    padding: 3em 2em;
    flex: 3;
    position: relative;
}

.content-system__container_distr{
    flex: 1;
    border-left: #c5c5cb 1px solid;
}

@media screen and (max-width: 1295px) {
    .content-system {
        font: 14px sans-serif;
    }
    .content-system__container {
        padding: 1.5em 1em;
    }
}