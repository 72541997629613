.header-system{
    width: 100%
}

.header-system__container{
    padding-right: 2.8em;
    height: 3.5em;
    align-self: center;
}

.header-system__container_text{
    flex-basis: 50em;
    flex-shrink: 100;
}

.header-system__container_scanform-button{
    margin: 0 0 0 auto;
    padding: 0 1em;
    max-width: 26%;
}

.header-system__button, .header-system__button.ui.button{
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 40000px;
    background-color: #6fc4dc;
    color: white;
    font: 1.3em sans-serif;
    margin: 0;
}

.header-system__button.ui.icon.button>.icon{
    margin-right: 0.4em !important;
}


.header-system__button:hover, .header-system__button.ui.button:hover{
    background-color: #538da5;
}