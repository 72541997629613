.panel{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.25em;
}

.panel_short{
    grid-template-rows: repeat(2, 1fr);
}

.panel_small{
    grid-template-columns: repeat(2, 1fr);
}

.panel_one-column{
    grid-template-columns: 1fr;
}

.panel__container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    /*background-color: #eeeeee;*/
    /*border: #6f6f6f 1px solid;*/
    /*border-radius: 3px;*/
}

.panel__input{
    height: 100%;
    width: 100%;
}

.panel__input:focus{
    outline: none;
}

.panel__input>.loader-placeholder{
    position: relative;
}

.panel__input.ui.input>input,
textarea.panel__input,
.panel__input>.loader-placeholder,
.panel__input.input-date__container .DateInput_input
{
    padding: 0 0.6em;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #ececf2;
    border: solid 2px #6f6f6f;
    font: 300 1.5em sans-serif;
    border-radius: 6px;
    resize: none;
}

.panel__input.input-date__container>.SingleDatePicker,
.panel__input.input-date__container>.SingleDatePicker>div,
.panel__input.input-date__container .SingleDatePickerInput,
.panel__input.input-date__container .DateInput
{
    height: 100%;
    background-color: transparent;
}

.panel__input.input-date__container
{
    height: 100%;
}

.panel__input_edit.ui.input>input, textarea.panel__input_edit{
    background-color: #f3f3f9;
}

.panel__input_edit.ui.input>input, textarea.panel__input_edit{
    background-color: #f3f3f9;
}

.panel__input_error.ui.input>input, textarea.panel__input_error{
    background-color: #ffe6ea;
    border-color: #f5516d;
    color: #f5516d;
}

.panel__input.ui.input::placeholder, .panel__input.ui.input::placeholder:not(:focus){
    color: #6f6f6f;
}

textarea.panel__input{
    padding: 0.5em 0.6em;
}


.panel__dropdown, .panel__dropdown.ui.dropdown {
    display: inline-flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    font: 300 1.5em sans-serif;
    margin: 0;
    background-color: #ececf2;
    box-sizing: border-box;
    border: none;
    border-radius: 6px;
    vertical-align: middle;
}

.panel__dropdown .icon {
    position: absolute;
    right: 1em;
    top: 50%;
    color: #c6c6c6;
    font-size: 0.7em;
    transform: translateY(-50%);
}

.panel__dropdown.ui.dropdown .menu {
    width: inherit;
    border: none;
    left: 50%;
    transform: translateX(-50%);
}

.panel__dropdown.ui.dropdown .menu>.item {
    display: flex;
    width: inherit;
    align-items: center;
    justify-content: center;
    background-color: #ececf2;
    font: 300 1em sans-serif;
}

.panel__dropdown.ui.dropdown .menu>.item:hover {
    background-color: #c5c5cb;
}

.panel__button, .panel__button.ui.button{
    text-align: center;
    padding: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    text-transform: uppercase;
    background-color: white;
    border: #808aa9 solid 2px;
    font-weight: bold;
    font-size: 1.3em;
    color: #808aa9;
}

.panel__button:hover, .panel__button.ui.button:hover{
    color: #616885;
    border-color: #616885;
    background-color: #d7e1ff;
}

.panel__button_red, .panel__button_red.ui.button,
.panel__button.ui.button.red{
    border-color: #ee2647;
    color: #ee2647;
}

.panel__button_red:hover, .panel__button_red:hover.ui.button,
.panel__button:hover.ui.button.red{
    border-color: #cc2645;
    color: #cc2645;
    background-color: #ffd9e0;
}

.panel__button_red, .panel__button_green.ui.button,
.panel__button.ui.button.green{
    border-color: #4c8a4c;
    color: #4c8a4c;
    background-color: white;
}

.panel__button_red:hover, .panel__button_green:hover.ui.button,
.panel__button:hover.ui.button.green{
    border-color: #387138;
    color: #387138;
    background-color: #bfdebf;
}

.panel__table{
    display: grid;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    grid-template-rows: repeat(auto-fill, 2.5em);
    border-radius: 3px;
    border: #6f6f6f 1px solid;
    color: black;
}

.panel__table__cell{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    border-right: #6f6f6f 1px solid;
    border-bottom: #6f6f6f 1px solid;
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
}

.panel__table__cell_current{
    font-weight: bolder;
    font-size: 1.8em;
    color: #2aa300;
}

.panel__table__cell_purple{
    font-weight: bolder;
    font-size: 1.8em;
    color: #6e69c7;
}

.panel__table__cell_disabled{
    color: #c5c5cb;
}

.panel__table__cell_right-end{
    border-right: none;
}

.panel__table__cell_bottom-end{
    border-bottom: none;
}

.panel__table__radio{

}

.panel__table__radio.ui.radio.checkbox{
    margin: 0!important;
}

.panel__table__radio.ui.radio.checkbox label{
    bottom: 0.1em;
    left: 0.05em;
}

.panel__table__radio.ui.radio.checkbox label:before{
    border-color: #616885;
}

.panel__table__radio.ui.radio.checkbox input:checked~label:after{
    background-color: #616885;
}

.panel__table__popup.ui.popup{
    box-sizing: border-box;
    padding: 0;
}