.header-products{
    display: flex;
    width: 100%;
    height: 8.25em;
    font: 1em sans-serif;
    align-items: center;
    padding: 0 1rem;
}

.header-products__container{
    display: flex;
    height: 100%;
    flex-flow: row;
    flex: 170;
    margin: 0;
    align-items: center;
    padding: 1em;
}

.header-products__container .table-products__table{
    margin: 1em 0;
}

.header-products__counter{
    padding: 0 0.5em 0 0.1em;
    flex: 0;
    width: fit-content;
    color: #726ec2;
    font-size: 2.75em;
}

.header-products__selector{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.selector__container{
    display: flex;
    height: 100%;
    flex: 1;
    justify-content: center;
}

.selector__button{
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    outline: 0;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}

.selector__button.yes{
    color: #26a666;
}

.selector__button.no{
    color: #b12945;
}

.selector__button:hover{
    font-weight: bold;
}