.popup-avail-prods{
    width: 93vw;
    font-size: 0.7em;
    min-height: 20em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1em;
}

.popup-avail-prods__table-group{
    width: 100%
}

.ReactTable .rt-tbody .rt-td.popup-avail-prods__cell_title{
    justify-content: flex-start;
    padding: 0 1em;
}