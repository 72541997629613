.card-hiding{
    background-color: #77aef5;
    border: #77aef5 1px solid;
    font: 500 1.3em sans-serif;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.card-hiding_hiding{
    background-color: white;
    border-color: #6f6f6f;
}