.table-wrapper {
  flex-direction: column;
  margin: 1.2em 0.5em 0.5em;
  min-width: 910px;
}

.table-wrapper__content {
  flex: 1;
  background-color: #fff;
  padding: 1em;
  border-bottom: 1px solid rgb(240, 241, 246);
}

.table-wrapper__footer {
  background-color: #fff;
  min-height: 5em;
  padding: 1em;
  line-height: 3em;
  width: 100%;
}


.table-wrapper__header {
  display: flex;
  align-items: center;
  background-color: #fff;
  min-height: 5em;
  font: 12px sans-serif;
  width: 100%;
  border-bottom: 1px solid rgb(240, 241, 246);
}

@media screen and (max-width: 1295px) {
  .table-wrapper__header {
    font: 10px sans-serif;
  }
}