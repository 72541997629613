.form-confirm-shipment{
    font-size: 1.5em;
}

.form-confirm-shipment__message{
    width: 100%;
    min-height: 6em;
    display: flex;
    flex: 1;
    font-size: 1.5em;
    align-items: center;
    justify-content: center;
}

.form-confirm-shipment__text{
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
}

.form-confirm-shipment__icon-area{
    width: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-confirm-shipment__button-line{
    width: 100%;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;

}

.form-confirm-shipment__button-container{
    display: flex;
    justify-content: space-evenly;
    width: 22em;
}

.form-confirm-shipment__button.ui.button{
    font-size: 1.3em;
    width: 8em;
}