.panel-labeled{
    padding: 0.5em 0;
}

.panel-labeled__label{
    margin-left: 2em;
    width: fit-content;
    height: fit-content;
    background-color: #6e6e70;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: white;
    text-transform: uppercase;
    font-size: 0.7em;
    line-height: 1em;
    padding: 0.3em 2em;
}

.panel-labeled__label_red{
    background-color: #ed0000;
}

.panel-labeled__content{
    padding: 1.2em;
    border: solid 1px #6e6e70;
    border-radius: 3px;
}

.panel-labeled__content_red{
    border-color: #ed0000;
}

.panel-labeled__grid{
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 2em;
}

.panel-labeled__container{
    display: flex;
    grid-column: span 1;
    grid-row: 1;
    align-items: center;
    justify-content: center;
}

.panel-labeled__container_double{
    grid-column: span 2;
}

.panel-labeled__container_right{
    justify-content: flex-end;
}

.panel-labeled__input.ui.input>input{
    padding: 0.2em;
    text-align: center;
    border-width: 1px;
    font-size: 1.3em;
}

.panel-labeled__dropdown.ui.dropdown{
    display: flex;
    background-color: white;
    border: #6e6e70 1px solid;
    padding: 0;
    width: 40%;
    height: 100%;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
}

.panel-labeled__dropdown.ui.dropdown .text{
    font: 300 1.3em sans-serif;
}

.panel-labeled__dropdown.ui.dropdown>.dropdown.icon{
    margin-left: 0.1em;
}

.panel-labeled__dropdown.ui.dropdown .menu{
    width: 100%;
}

.panel-labeled__dropdown-label{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font: 500 1.3em sans-serif;
    margin: 0 0.5em;
}

.panel-labeled__input.ui.input.error>input{
    background-color: #ffe6ea;
    border-color: #f5516d;
    color: #f5516d;
}

.panel-labeled__input.ui.input.error>input::placeholder{
    color: #f5516d;
}