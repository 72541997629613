.bottom-labeled{
    margin: 0 0.2em;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.bottom-labeled__label{
    font-size: 0.5em;
    text-transform: uppercase;
}
