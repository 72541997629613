.grid-market-ship-stats{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2em;
}

.grid-market-ship-stats__container{
    height: 13em;
}

.grid-market-ship-stats__container .card-white{
    font-size: 1em;
}

@media screen and (max-width: 1295px) {
    .grid-market-ship-stats {
        grid-gap: 1em;
    }
    .grid-market-ship-stats__container{
        height: 11em;
    }
}