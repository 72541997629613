.panel-track-order{
    grid-template-areas:
        "shipping-com shipping-com        track"
        "tracking-num tracking-num tracking-num";
}

.panel-track-order__container_tracking-num { grid-area: tracking-num }
.panel-track-order__container_shipping-com { grid-area: shipping-com }
.panel-track-order__container_track { grid-area: track }

.panel-track-order__dropdown.ui.dropdown {
    border: solid 1px #6f6f6f;
    background-color: white;
    font-size: 1.4em;
    font-weight: 500;
}

.panel-track-order__dropdown.ui.dropdown .icon{
    display: none;
}