.grid-product {
    display: grid;
    height: 153px;
    grid-template-columns: 364fr 240fr 610fr 510fr;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 6px;
}

.grid-product_lite {
    display: grid;
    height: 51px;
    grid-template-rows: 1fr;
}

.grid-product__card {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #ececf2;
    border: 1px #6f6f6f solid;
    border-radius: 6px;
    grid-row: 1/4;
    font: 500 1.5em sans-serif;
    padding: 0.4em;
}

.grid-product__id{
    margin: 0;
}

.grid-product__card_ids_lite{
    grid-row: 1;
    grid-column: 1;
}

.grid-product__card_title_lite{
    grid-row: 1;
    grid-column: 2/fill;
}

.grid-product__image{
    max-height: 100%;
    max-width: 100%;
}

.grid-product__card_image {
    background-color: white;
    padding:0.2em;
}

.grid-product__card_brand {
    grid-row: 1
}

.grid-product__card_category{
    grid-row: 2/4
}

