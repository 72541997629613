.input-disguised{
    width: -moz-available;
    cursor: pointer;
    word-break: break-all;
}

.input-disguised__input{
    width: 80%;
    background-color: white;
    font: inherit;
    border: none;
}

.input-disguised__textarea{
    width: 100%;
    height: 100%;
    font: inherit;
    border: none;
    resize: none;
}

.input-disguised__textarea:focus{
    outline: none;
    text-decoration: underline;
}

.input-disguised__input:focus{
    outline: none;
    border-bottom: #585960 1px solid;
}

.input-disguised_error, .input-disguised_error:focus{
    color: red;
    border-bottom: red 2px solid;
}

.input-disguised__textarea_error, .input-disguised_error:focus{
    border-bottom: none;
}