.panel-inv-props{
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
        "weight weight size      size      price  qty"
        "uom    uom    unpublish unpublish delete delete";
}

.panel-inv-props__container_weight{ grid-area: weight }
.panel-inv-props__container_size{ grid-area: size }
.panel-inv-props__container_price{ grid-area: price }
.panel-inv-props__container_qty{ grid-area: qty }
.panel-inv-props__container_uom{ grid-area: uom }
.panel-inv-props__container_unpublish{ grid-area: unpublish }
.panel-inv-props__container_delete{ grid-area: delete }

.panel-inv-props__button.ui.button{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: #f5516d;
    border: none;
    border-radius: 3px;
    color: white;
    font-size: 2.5em;
    margin: 0;
    padding: 0;
}

.panel-inv-props__button.ui.button:hover{
    background-color: #952e41;
}