.panel-size{
    grid-template-areas:
        "weight weight isfood"
        "weight weight isfood"
        "weight weight isfood"
        "length width  height"
        "length width  height"
        "button button button"
;
}

.panel-size__container_length{
    grid-area: length;
}

.panel-size__container_width{
    grid-area: width;
}

.panel-size__container_height{
    grid-area: height;
}

.panel-size__container_weight{
    grid-area: weight;
}

.panel-size__container_is-food{
    grid-area: isfood;
}

.panel-size__container_button{
    grid-area: button;
}

.panel-size__card-labeled_weight{
    font-size: 4em;
    padding-bottom: 0.1em;
}

.panel-size__card-label_food{
    color: #59d576;
    border-color: #59d576;
}

.panel-size__card-labeled_food__content{
    font-size: 2.2em;
    font-weight: bold;
    padding-bottom: 0.1em;

}

.panel-size__card-labeled_food__label{
    background-color: #59d576;
}

.panel-size__card-labeled_label_disabled{
    background-color: #c5c5cb;
    font-size: 0.8em;
    font-weight: normal;
}

.panel-size__card-labeled_disabled{
    border-color: #c5c5cb;
    color: #c5c5cb;
}

.panel-size__card-labeled_old{
    color: #c5c5cb;
}
