.panel-customer-info{
    grid-template-areas:
        "name    name    name"
        "from    from    mail"
        "address address address"
        "address address address"
        "address address address"
        "address address address";
}

.panel-customer-info__container_name{
    grid-area: name;
    text-transform: capitalize;
}

.panel-customer-info__container_address{
    grid-area: address;
}

.panel-customer-info__container_from{
    grid-area: from;
    text-transform: capitalize;
}
.panel-customer-info__container_mail{ grid-area: mail; }

.card__button-mail{
    background-color: #828baa;
    color: white;
    border: none;
    align-items: center;
    justify-content: center;
}

.card__button-mail:hover{
    background-color: #616885;
}

i.icon.card__button-mail_icon{
    display: flex;
    width: 100%;
    font-size: 2.3em;
    align-items: center;
    justify-content: center;
}