header, footer {
  background-color: #fff;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content__main {
  flex: 1;
  background-color: rgb(240, 241, 246);
  position: relative;
  overflow-x: scroll;
}

.content__main::-webkit-scrollbar {
  height: 0;
  background-color: rgb(240, 241, 246);
}

.content__modal-triggers{
  display: none;
}

footer {
  height: 3em;
  line-height: 3em;
  padding: 0 1em;
  box-sizing: border-box;
}
