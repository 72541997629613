.grid-orders {
    height: 34em;
    /*height: 30.75em;*/
    grid-template-columns: 21fr 25fr 25fr 17fr 27fr;
    grid-template-rows: 7fr 3fr;
    grid-template-areas:
            "order-info customer-info item-info    buy-info   distr-info"
            "notes      notes         track-order  buy-inputs status";
}

.grid-orders__container{
    grid-row: 1;
    padding: 1.563em 1.125em 0.875em;
    border-left: #6f6f6f 1px solid;
    border-bottom: #6f6f6f 1px solid;
}

.grid-orders__container_bottom{
    padding: 1.563em 1.126em 1.563em;
    border-left: none;
    border-bottom: none;
}

.grid-orders__container_order-info{ grid-area: order-info;  border-left: none;}
.grid-orders__container_customer-info{ grid-area: customer-info;}
.grid-orders__container_item-info{ grid-area: item-info;}
.grid-orders__container_buy-info{ grid-area: buy-info;}
.grid-orders__container_distr-info{ grid-area: distr-info;}
.grid-orders__container_notes{ grid-area: notes;}
.grid-orders__container_track-order{ grid-area: track-order;}
.grid-orders__container_buy-inputs{ grid-area: buy-inputs;}
.grid-orders__container_status{ grid-area: status;}

.grid-orders__status-dropdown, .grid-orders__status-dropdown.ui.dropdown {
    font-size: 3em;
    text-transform: uppercase   ;
}

.grid-orders__status-dropdown .icon {
    font-size: 1em;
    right: 0.2em;
}

.grid-orders__status-dropdown.ui.dropdown .menu {
    width: inherit;
    font-size: 0.5em;
}

.grid-orders__status-dropdown.ui.dropdown .menu>.item{
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    padding: 0.2em 0 !important;

}

.grid-orders__container_export{  background-color: #eba0ad;  }
.grid-orders__container_archive{  background-color: #499ee5;  }
.grid-orders__container_ship-ready{  background-color: #72d6e4;  }
.grid-orders__container_shipped{  background-color: #59d576;  }
.grid-orders__container_service{  background-color: #b6b6b6;  }
.grid-orders__container_on-hold{  background-color: #feec76;  }
.grid-orders__container_asap{  background-color: #aa92d9;  }
.grid-orders__container_cancelled{  background-color: #ee2647;  }
.grid-orders__container_reorders{  background-color: #eca127;  }


@media screen and (max-width:1280px) {
    .grid-orders__status-dropdown .icon{
        display: none;
    }
    .grid-orders {
        grid-template-columns: 20fr 24fr 26fr 20fr 26fr;
    }
}
