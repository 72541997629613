.page__working-area {
    display: flex;
    flex-flow: column;
    margin: 15px;
    background-color: white;
    box-shadow: 0 0 20px lightgrey;
}

/*.working-area__table-products {*/
    /*margin:24px;*/
/*}*/