.card-labeled{
    background-color: white;
    border: #4b4b4b 1px solid;
    color: #4b4b4b;
    font: 500 1.3em sans-serif;
    align-items: center;
    justify-content: flex-start;
}

.card-labeled__label{
    width: 100%;
    height: 1.625em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4b4b4b;
    font-weight: lighter;
    font-size: 0.8em;
    color: white;
    text-transform: uppercase;
}

.card__labeled_clickable{
    cursor: pointer;
    width: 100%;
}

.card-labeled__label.card-labeled__label_caution{
    background-color: #d5536c;
}

.card-labeled__label.card-labeled__label_strange{
    background-color: #6e69c7;
    font-weight: bold;
    font-size: 1.04em;
}

.card-labeled.card-labeled_caution{
    border-color: #d5536c;
    color: #d5536c;
}

.card-labeled.card-labeled_strange{
    border-color: #6e69c7;
    color: #6e69c7;
}

.card-labeled__content{
    width: 100%;
    display: flex;
    flex: 1;
    padding: 0 0 0.3em;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    font-size: 1.7em;
}

.card-labeled__content .input-disguised{
    text-align: center;
    width: 100%;
}

.card-labeled__content .input-disguised__input{
    text-align: center;
    width: 100%;
}

.card-labeled__content_list{
    padding: 0.2em 0.7em;
    align-items: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 0.8em;
}

.card-labeled__content_list .input-disguised__input{
    text-align: left;
}

.card-labeled__line{
    margin: 0;
}
.card-labeled__line_capitalize{
    text-transform: capitalize;
}


.card__popup_container{
    width: 30em;
}