.panel-order-info{
    grid-template-areas:
        "id      id"
        "id      id"
        "ordered ship-by"
        "ordered ship-by"
        "market  market"
        "deal    deal";
}

.panel-order-info__container_id{ grid-area:id }
.panel-order-info__container_ordered{ grid-area:ordered }
.panel-order-info__container_ship-by{ grid-area:ship-by }
.panel-order-info__container_market{ grid-area:market }
.panel-order-info__container_deal{ grid-area:deal }
