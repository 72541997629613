.shipped-ordered-pair{
    display: flex;
    flex-flow: row;
}

.shipped-ordered-pair__labeled_shipped{
    color: #de5b01
}

.shipped-ordered-pair__labeled_ordered {
    color: #4b4b4b;
}