
.table-shipping-services__table{
    grid-template-columns: 14em 15fr 16em 7.5em 8em 7.5em 2.5em;
    grid-auto-rows: 2.6em;
    border-bottom: none;
    height: 100%;
    width: 100%;
    font-weight: 500;
    color: #4b4b4b;
    margin-bottom: 2em;
}

.table-shipping-services__cell_service{
    justify-content: flex-start;
}

.table-shipping-services__header{
    font-weight: bold;
    justify-content: center;
}

.table-shipping-services__cell{
    width: 100%;
    height: 100%;
    padding: 0.2em 0.5em;
    box-sizing: border-box;
}

.table-shipping-services__cell_unprofitable{
    color: #d5536c;
    font-weight: bold;
}

.table-shipping-services__cell_radio{
    padding: 0.2em;
    font-size: 1em;
}

.table-shipping-services__cell_capitalize{
    text-transform: capitalize;
}

.table-shipping-services__image{
    width: 100%;
    height: 100%;
    background: center no-repeat;
    background-size: contain;
    background-origin: padding-box;
}

.table-shipping-services__radio{
    width: 2.1em;
    height: 2.1em;
}

.table-shipping-services__radio.ui.radio.checkbox{
    font-size: 1em;
}

.table-shipping-services__radio.ui.radio.checkbox label{
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.table-shipping-services__radio.ui.radio.checkbox label:before{
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.table-shipping-services__radio.ui.radio.checkbox label:after{
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

