.popup-shipping{
    display: flex;
    flex-flow: column;
    padding: 0.5em;
    font-size: 0.8em;
    min-width: 85em;
    min-height: 20em;
    line-height: 3em;
}

.popup-shipping__package-selection-container{
    width: 100%;
    max-width: 85em;
    padding-top: 1em;
}

.popup-shipping__package-selection-title{
    font-size: 1.5em;
    font-weight: bold;
}

.popup-shipping__message__button-container{
    font-size: 0.5em;
    padding-top: 2em;
}

.popup-shipping__message__line{
    padding-top: 1em;
}

.popup-shipping__button-row{
    display: flex;
    flex-flow: row;
    padding: 0;
    margin: 0;
    justify-content: flex-end;
    align-items: center;
}

.popup-shipping__checkbox.ui.checkbox.toggle{
    padding-right: 2em;
}

.popup-shipping__message{
    font-size: 2em;
    text-align: center;
    align-self: center;
    justify-self: center;
    margin-top: 2em;
}

.popup-shipping__button.ui.button {
    font-size: 2em;
    padding: 0.5em 1em;
}

.popup-shipping__table__cell_service{
    justify-content: flex-start;
}

.popup-shipping__table__cell{
    width: 100%;
    height: 100%;
    padding: 0.2em 0.5em;
    box-sizing: border-box;
}

.popup-shipping__table__cell_capitalize{
    text-transform: capitalize;
}

.popup-shipping__table__cell_radio{
    padding: 0.2em;
    font-size: 1em;
}

.popup-shipping__table__image{
    width: 100%;
    height: 100%;
    background: center no-repeat;
    background-size: contain;
    background-origin: padding-box;
}

.popup-shipping__table__radio{
    width: 2.1em;
    height: 2.1em;
}

.popup-shipping__table__radio.ui.radio.checkbox{
    font-size: 1em;
}

.popup-shipping__table__radio.ui.radio.checkbox label{
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.popup-shipping__table__radio.ui.radio.checkbox label:before{
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.popup-shipping__table__radio.ui.radio.checkbox label:after{
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1280px){
    .popup-shipping{
        font-size: 0.6em;
    }
}