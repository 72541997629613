.button-picture{
    width: 100%;
    height: 100%;
    border: none;
    background: transparent center no-repeat;
    background-size: contain;
    background-origin: content-box;
    border-radius: 6px;
    -webkit-appearance: none;
    outline: none;
    padding: 0;
    cursor: pointer;
}

.button-picture:visited{
    outline: none;
}

.button-picture:hover{
    background-color: #f3f3f9;
}