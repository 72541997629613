.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  padding: 0 1%;
  position: relative;
  align-self: flex-end;
}

.pagination-info {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.pagination-info__dropdown {
  margin-right: 1em;
  height: 2em;
  position: absolute;
}

.pagination-info__dropdown .ui.dropdown {
  border: 1px solid black;
  height: 2em;
  border-radius: 50%;
}

.pagination-block .ui.menu {
  border: none;
  box-shadow: none;
  font-size: 1em;
}

.pagination-block .ui.menu > .item {
  border: none;
  border-radius: 50%;
  color: rgba(128, 132, 144, 1);
  background-color: #fff;
  margin-right: 2px;
  min-width: 38px;
}

.pagination-block .ui.menu > .item[type=firstItem],
.pagination-block .ui.menu > .item[type=prevItem],
.pagination-block .ui.menu > .item[type=nextItem],
.pagination-block .ui.menu > .item[type=lastItem] {
  background-color: rgb(240, 241, 246);
  border-radius: 50%;
}

.pagination-block .ui.menu > .item:focus {
  outline: 0;
}

.pagination-block .ui.menu > .item:before {
  content: none;
}

.pagination-block .ui.menu > .item.active {
  background-color: rgba(113, 108, 200, 1);
  color: #ffffff;
}

.pagination-info__dropdown {
  height: 2.2em;
  padding: 0.54em 0 0.54em 0.94em;
  flex-basis: 15em;
  flex-shrink: 15;
  background-color: #ececf2;
  border: solid 1px #c6c6c6;
  box-sizing: border-box;
  font: 300 1.2em Lato;
  border-radius: 20px;
  vertical-align: middle;
  line-height: 1.1em;
  white-space: nowrap;
}

.pagination-info__dropdown .text {
  margin-right: 5px;
}

.pagination-info__dropdown .icon {
  position: absolute;
  right: 1em;
  top: 50%;
  color: #c6c6c6;
  font-size: 0.7em;
  transform: translateY(-50%);
}

.pagination-info__dropdown.ui.dropdown .menu {
  border: solid 1px #c6c6c6;
}

.pagination-info__dropdown.ui.dropdown .menu > .item {
  background-color: #ececf2;
  font: 300 1em sans-serif;
}

.pagination-wrapper {
  color: rgba(116, 117, 123, 1);
}

.pagination-info__dropdown-wrapper {
  position: absolute;
}

.pagination-info__text {
  margin-left: 4.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2em;
}

/*color: rgba(128, 132, 144, 1); grey*/
/*background-color: rgba(113, 108, 200, 1); violet*/
