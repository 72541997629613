.panel-order-count-by-distr {
    display: flex;
    flex-flow: column;
}

.panel-order-count-by-distr__container {
    height: fit-content;
    color: #4b4b4b;
    margin-bottom: 0.3em;
}

.panel-order-count-by-distr__cell {
    font-weight: bold;
}

.panel-order-count-by-distr__text{
    font: lighter 1.3em sans-serif;
    flex-flow: row;
    padding: 0.2em 0;
}

.panel-order-count-by-distr__text_count {
    font-weight: bold;
    color: #6e69c7;
    display: inline;
    margin-right: 0.3em;
}

@media screen and (max-width: 1295px) {
    .panel-order-count-by-distr{
        font-size: 0.8em;
    }
}