.table-market-ship-count {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1.5fr 1fr 1fr 1.2fr;
    grid-template-rows: repeat(3, 1fr);
}

.table-market-ship-count__container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-right: #6f6f6f 1px solid;
    border-bottom: #6f6f6f 1px solid;
    font-size: 1em;
    font-weight: bold;
    padding: 0.3em 0;
    line-height: 1em;
}

.table-market-ship-count__container_double {
    grid-column: span 2;
}

.table-market-ship-count__container_label {

}

.table-market-ship-count__container_last {
    border-right: none;
}

.table-market-ship-count__container_icon i.icon{
    margin: 0 5%;
}

.table-market-ship-count__total {
    border-bottom: none;
    font-size: 1.3em;
}

.table-market-ship-count__container_icon {
    font-size: 1.5em;
    cursor: pointer;
}

.table-market-ship-count__total.table-market-ship-count__container_label{
}

.table-market-ship-count__shipped {
    color: #00ca66
}

.table-market-ship-count__not-shipped {
    color: #f5516b
}

@media screen and (max-width: 1295px) {
    .table-market-ship-count{
        font-size: 0.8em;
    }
}