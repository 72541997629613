.panel-item_info{
    grid-template-areas:
        "name  name  name"
        "name  name  name"
        "image image uom"
        "image image uom"
        "image image lbs"
        "image image lbs";
}

.panel-item_info__container_name{ grid-area: name; }
.panel-item_info__container_image{ grid-area: image; }
.panel-item_info__container_uom{ grid-area: uom; }
.panel-item_info__container_lbs{ grid-area: lbs; }
