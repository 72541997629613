.header-distr{
    display: flex;
    width: 100%;
    height: 6.25em;
    font: 12px sans-serif;
    align-items: center;
    padding: 0 1rem;
}

.header-distr__container{
    display: flex;
    flex-flow: row;
    flex: 170;
    margin: 0;
    align-items: center;
    padding: 0 0 0 1em;
}

.header-distr__counter{
    padding: 0 0.5em 0 0.1em;
    flex: 0;
    width: fit-content;
    color: #726ec2;
    font-size: 2.75em;
}

.header-distr__container_button{
    flex: 244;
    height: 2.4rem;
    justify-self: flex-end;
    padding: 0 1.25em;
    /*border-left: black 1px solid;*/
}

.header_distr__container_bulk{
    width: 5em;
    height: 4em;
    padding: 0 0.5em;
    align-self: center;
    flex: none;
}

.header-distr__container_space{
    flex: 420;
}

.header-distr__dropdown-label{
    min-width: fit-content;
    font: bolder 1.5em sans-serif;
    margin-right: 0.375em;
}

.header-distr__dropdown {
    height: 2.23em;
    padding: 0.5em 0.875em;
    width: -moz-available;
    width: -webkit-fill-available;
    background-color: #ececf2;
    border: none;
    box-sizing: border-box;
    font: 300 1.5em sans-serif;
    border-radius: 6px;
    vertical-align: middle;
}


.header-distr__dropdown .icon {
    position: absolute;
    right: 1em;
    top: 50%;
    color: #c6c6c6;
    font-size: 0.7em;
    transform: translateY(-50%);
}

.header-distr__dropdown.ui.dropdown .menu {
    width: inherit;
    border: none;
}

.header-distr__dropdown.ui.dropdown .menu>.item {
    width: inherit;
    background-color: #ececf2;
    font: 300 1em sans-serif;
}

.header-distr__dropdown.ui.dropdown .menu>.item:hover{
    background-color: #c5c5cb;
}

.header-distr__button, .header-distr__button.ui.button{
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 40000px;
    background-color: #6fc4dc;
    color: white;
    font: 1.3em sans-serif;
    margin: 0;
}

.header-distr__button.ui.icon.button>.icon{
    margin-right: 0.4em !important;
}

.header-distr__button:hover, .header-distr__button.ui.button:hover{
    background-color: #538da5;
}

.header-distr__button_blue, .header-distr__button_blue.ui.button {
    background-color: #2286dc;
}

.header-distr__button_blue:hover, .header-distr__button_blue.ui.button:hover{
    background-color: #124571;
}

.header-distr__button_green, .header-distr__button_green.ui.button{
    background-color: #4ccbb0;
}

.header-distr__button_green:hover, .header-distr__button_green.ui.button:hover{
    background-color: #318066;
}

@media screen and (max-width: 1295px) {
    .header-distr__container_button{
        padding: 0 1em;
        flex: 221;
        box-sizing: border-box;
    }
    .header-distr {
        font: 10px sans-serif;
    }

    .header-distr__dropdown .icon {
        right: 0.5em;
    }
}