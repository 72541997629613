.shipping-notes{
    font-size: 1.5em;
}

.shipping-notes__title{
    font-weight: bold;
}

.shipping-notes__input.ui.input>input{
    padding: 0.3em 0.5em;
}