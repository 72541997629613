.card-white{
    background-color: white;
    border: #6f6f6f 1px solid;
    font: 500 1.3em sans-serif;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.card-white .input-disguised__input {
    width: 100%;
}

.card-white .input-disguised {
    width: 90%;
}



