.table-products-to-ship {
    width: 70em;
    grid-template-columns: 10fr 16fr 10em 4fr 4fr 2.5em;
    grid-auto-rows: 9.2em;
}

.table-products-to-ship__checkbox {

}

