.popup-order-bulk-edit{
    font-size: 12px;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
}

.popup-order-bulk-edit__input_panel{
    display: flex;
    flex-flow: row;
}

.popup-order-bulk-edit_title{
    font-size: 1.5em;
}

.popup-order-bulk-edit__container{
    height: inherit;
    padding: 0 1.126em 1.563em;
}

.popup-order-bulk-edit__container_notes{
    width: 40em;
    height: 7em;
}

.popup-order-bulk-edit__container_status{
    width: 22.5em;
    height: 7em;
}

.popup-order-bulk-edit__container_submit{
    padding: 1.563em 1.126em;
}

.popup-order-bulk-edit__container_button.ui.button{
    margin-right: 0;
}

@media screen and (max-width: 1218px){
    .popup-order-bulk-edit{
        font-size: 10px;
    }
}