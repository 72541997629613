.table {
    display: table;
    border-collapse: separate;
    width: -webkit-fill-available;
    width: -moz-available;
    border: #585960 1px solid;
    border-radius: 6px;
    border-spacing: 0;
    font: 1.2em sans-serif;
}

.table__column {
    display: table-cell;
    text-align: center;
    height: 2.5em;
    padding: 0.6em 0;
    border-left: #585960 1px solid;
    border-top: #585960 1px solid;
    vertical-align: middle;
    background-color: white;
}

.ReactTable .rt-thead .rt-th.table__column, .ReactTable .rt-thead .rt-td.table__column{
    padding: 0.6em 0;
    border-right: none;
}

.ReactTable.table .rt-tbody .rt-tr-group{
    border-bottom: none;
}

.ReactTable.table .rt-tbody .rt-tr-group:last-child {
    border-bottom: transparent 1px solid;
}

.ReactTable.table .rt-tbody .rt-td{
    border-right: none;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0;
}

.table__head{
    background-color: mediumaquamarine;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    box-shadow: none;
    border-top: none;
}

.table__head_marketplaces{
    background-color: dodgerblue;
}

.ReactTable .rt-td.table__column_image{
    padding: 0;
}

.table__column:first-child {
    border-left: none;
}

.table__image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
    padding: 0.2em;
}

.table__head:first-child {
    border-top-left-radius: 6px;
}
.table__head:last-child {
    border-top-right-radius: 6px;
}
.rt-tr-group:last-child .table__column:first-child {
    border-bottom-left-radius: 6px;
}
.rt-tr-group:last-child .table__column:last-child {
    border-bottom-right-radius: 6px;
}


