.panel-inv-info{
    grid-template-columns: 41fr 62fr;
    grid-template-areas:
        "image name"
        "image brand";
}

.panel-inv-info__container_image{
    grid-area: image;
}

.panel-inv-info__container_name{
    grid-area: name;
}

.panel-inv-info__container_brand{
    grid-area: brand;
}

