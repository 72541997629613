.table-products {
    width: -webkit-fill-available;
    width: -moz-available;
    border: #6f6f6f 1px solid;
    border-collapse: collapse;
    font: 0.8em sans-serif;
}

.table-products__table {
    margin: 0 1em 1em;
}

.table-products__grid-product {
    margin: 1em;
}

.table-products__content {
    display: flex;
    flex-flow: column;
}

.table-products__cell {
    border: #6f6f6f 1px solid;
}

.table-products__cell_head{
    height: 7em;
    background-color: #ececf2;
}

.table-products__search-bar{
    max-width: 1665px;
    width: -webkit-fill-available;
    width: -moz-available;
    display: flex;
    align-items: center;
    margin: auto 10px;
}

.table-products__column_first {
    width: 5em;
    min-width: 5em;
}

.table-products__content_first{
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: center;
}

.table-products__input {
    padding: 0 1.5em;
    height: 2.23em;
    width: 100%;
    box-sizing: border-box;
    background-color: #ececf2;
    border: solid 2px #6f6f6f;
    font: 300 1.5em sans-serif;
    border-radius: 6px;
}

.table-products__input-container {
    flex-basis: 26em;
    flex-shrink: 15;
    margin-right: 6px;
    min-width: 1em;
}

.table-products__input-container_id {
    flex-basis: 36em;
    flex-shrink: 13;
    margin-right: 6px;
    min-width: 1em;
}


.table-products__label {
    margin: auto 0.5em auto 1em;
    font: bolder 1.5em sans-serif;
}

.table-products__input:focus{
    outline: none;
}

.table-products__input_brand {

}

.table-products__dropdown {
    height: 2.23em;
    padding: 0.54em;
    flex-basis: 15em;
    flex-shrink: 15;
    background-color: #ececf2;
    border: solid 1px #c6c6c6;
    box-sizing: border-box;
    font: 300 1.5em sans-serif;
    border-radius: 6px;
    vertical-align: middle;
}

.table-products__dropdown .icon {
    position: absolute;
    right: 1em;
    top: 50%;
    color: #c6c6c6;
    font-size: 0.7em;
    transform: translateY(-50%);
}

.table-products__dropdown.ui.dropdown .menu {
    border: solid 1px #c6c6c6;
}

.table-products__dropdown.ui.dropdown .menu>.item {
    background-color: #ececf2;
    font: 300 1em sans-serif;
}


.table-products__dropdown_distributor {
    flex-basis: 10em;
    flex-shrink: 10;
}

.table-products__checkbox {
    border: none;
}

.table-products__checkbox label::before{
    background: #e4e2ef !important;
}

.table-products__checkbox.checked label::before{
    background: #6e69c7 !important;
}

.table-products__checkbox.checked label::after{
    color: white !important;
}

/*
@media screen and (max-width: 1500px) {
    .table-products {
        font: 12px sans-serif;
    }
}
*/
@media screen and (max-width: 1295px) {
    .table-products {
        font: 10px sans-serif;
    }
}
