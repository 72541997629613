.card-opinion{
    background-color: #d5536c;
    border: #d5536c 1px solid;
    font: 500 1.3em sans-serif;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white; 
}

.card-opinion_good{
    background-color: #7fcbb0;
    border-color: #7fcbb0;
}