.table-inventory {
    width: -webkit-fill-available;
    width: -moz-available;
    border: #6f6f6f 1px solid;
    border-collapse: collapse;
    font: 0.8em sans-serif;
}

.table-inventory__table {
    margin: 15px 10px;
}


.table-inventory__content {
    display: flex;
    flex-flow: column;
}

.table-inventory__input-container {
    height: 100%;
    padding: 1.625em 1.125em 1.5em;
    min-width: 1em;
    border-left: #6f6f6f 1px solid;
}

.table-inventory__search-bar {
    display: flex;
}

.table-inventory__input-container:first-child{
    border-left: none;
}

.table-inventory__cell {
    padding: 0;
    border: #6f6f6f 1px solid;
}

.table-inventory__cell_head{
    height: fit-content;
    background-color: #ececf2;
}

.table-inventory__column_first {
    width: 5em;
    min-width: 5em;
}

.table-inventory__content_first{
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: center;
}

.table-inventory__input {
    padding: 0 1.5em;
    height: 2.23em;
    width: 100%;
    box-sizing: border-box;
    background-color: #ececf2;
    border: solid 2px #6f6f6f;
    font: 300 1.5em sans-serif;
    border-radius: 6px;
}

.table-inventory__input::placeholder{
    color: #6f6f6f;
}

.table-inventory__label {
    margin: auto 12px auto 41px;
    font: bolder 1.5em sans-serif;
}

.table-inventory__input:focus{
    outline: none;
}

.table-inventory__dropdown {
    height: 2.23em;
    padding: 0.54em;
    flex-basis: 15em;
    flex-shrink: 15;
    background-color: #ececf2;
    border: solid 1px #c6c6c6;
    box-sizing: border-box;
    font: 300 1.5em sans-serif;
    border-radius: 6px;
    vertical-align: middle;
}

.table-inventory__dropdown .icon {
    position: absolute;
    right: 1em;
    top: 50%;
    color: #c6c6c6;
    font-size: 0.7em;
    transform: translateY(-50%);
}

.table-inventory__dropdown.ui.dropdown .menu {
    width: inherit;
    border: solid 1px #c6c6c6;
}

.table-inventory__dropdown.ui.dropdown .menu>.item {
    width: inherit;
    background-color: #ececf2;
    font: 300 1em sans-serif;
}

.table-inventory__dropdown.ui.dropdown .menu>.item:hover{
    background-color: #c5c5cb;
}

.table-inventory__dropdown_distributor {
    flex-basis: 10em;
    flex-shrink: 10;
}

.table-inventory__checkbox {
    border: none;
}

.table-inventory__checkbox label::before{
    background: #e4e2ef !important;
}

.table-inventory__checkbox.checked label::before{
    background: #6e69c7 !important;
}

.table-inventory__checkbox.checked label::after{
    color: white !important;
}

.table-inventory__button, .table-inventory__button.ui.button{
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 40000px;
    background-color: #6fc4dc;
    color: white;
    font: 1.3em sans-serif;
}

.table-inventory__button.ui.icon.button>.icon{
    margin-right: 0.4em !important;
}

.table-inventory__button, .table-inventory__button.ui.button:hover{
    background-color: #538da5;
}

/*
.table-inventory__content .grid-orders {
    height: auto;
    max-height: 30.75em;
}
*/

@media screen and (max-width: 1295px) {
    .table-inventory {
        font: 10px sans-serif;
    }

    .table-inventory__search-bar{
        grid-template-columns: 20fr 24fr 26fr 20fr 26fr;
    }
}


